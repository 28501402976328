<template>
  <div>
    <loader v-if="loading" />
    <template v-else>
      <div class="mb-4">
        <div class="d-flex justify-content-end margin-guide-not-visible">
          <erase-icon-button
            v-if="removeButtonVisible"
            class="mr-2"
            @click="onDelete"
          />
        </div>

        <div class="d-flex justify-content-end mt-3">
          <set-sales-tax-rate :disabled="!canEdit" />
        </div>
      </div>

      <template v-if="!isRevenueStreamsEmpty">
        <ib-card
          :animation="false"
          class="mb-4 pt-4 pb-1"
        >
          <revenue-distribution />
        </ib-card>

        <ib-card
          :animation="false"
          class="mb-4 pt-4 pb-1"
        >
          <revenue-over-time />
        </ib-card>

        <ib-card
          :animation="false"
          class="pt-4 pb-2"
        >
          <revenue-streams-table />
        </ib-card>
      </template>
      <empty-placeholder v-else />
    </template>

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="revenueStreamsExamples"
      :visible="guideVisible || guideVisibleFirstTime"
      @close="guideVisible = false; guideVisibleFirstTime = false;"
    />
    <!-- /Info Guide Drawer -->

    <!-- Ai Buddy Drawer -->
    <ai-buddy-drawer
      :visible="aiBuddyDrawerVisible"
      type="revenue-stream"
      @close="aiBuddyDrawerVisible = false"
    />
    <!-- /Ai Buddy Drawer -->

    <!-- Dialog Add Revenue Stream -->
    <dialog-add-revenue-stream
      v-if="canEdit"
      :dialog-add-revenue-stream-visible="dialogAddNewVisible"
      @close-dialog-add-revenue-streams="dialogAddNewVisible = false"
      @close-dialog-on-button="dialogAddNewVisible = false"
      @on-update-revenue-streams-view="onUpdateAddNewDialog()"
    />
    <!-- /Dialog Add Revenue Stream -->
  </div>
</template>

<script>
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import DialogAddRevenueStream from '@/views/Home/StoryMode/Forecast/RevenueStreams/Dialogs/DialogAddRevenueStream'
import EmptyPlaceholder from '@/views/Home/StoryMode/FinancialPlan/Components/EmptyPlaceholder'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import EventBus from '@/event-bus'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'
import Loader from '@/components/Loader'
import MixinAiBuddyDrawer from '@/mixins/aiBuddyDrawer'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import RevenueDistribution from '@/views/Home/StoryMode/FinancialPlan/Components/Revenue/RevenueDistribution'
import RevenueOverTime from '@/views/Home/StoryMode/FinancialPlan/Components/Revenue/RevenueOverTime'
import RevenueStreamsTable from '@/views/Home/StoryMode/Forecast/RevenueStreams/Tables/RevenueStreamsTable'
import SetSalesTaxRate from '@/views/Home/StoryMode/Forecast/RevenueStreams/SetSalesTaxRate'

import { mapState } from 'vuex'

export default {
  name: 'Revenue',

  components: {
    AiBuddyDrawer,
    InfoGuideDrawer,
    DialogAddRevenueStream,
    EmptyPlaceholder,
    EraseIconButton,
    Loader,
    RevenueDistribution,
    RevenueOverTime,
    RevenueStreamsTable,
    SetSalesTaxRate
  },

  mixins: [MixinApiCalculations, MixinGuide, MixinIdeaRoles, MixinDeleteIdeaExamples, MixinAiBuddyDrawer],

  data () {
    return {
      loading: false,
      dialogAddNewVisible: false
    }
  },

  computed: {
    ...mapState(['idea']),

    isRevenueStreamsEmpty () {
      return !this.idea.storyMode.forecast.revenueStreams.revenueStreams.length
    },

    removeButtonVisible () {
      return this.canEdit && this.revenueHasItemsFromTemplate
    }
  },

  mounted () {
    this.openGuide('revenueStreamFinancialPlanGuide')
    EventBus.$on('add-edit', () => {
      this.onShowAddNewDialog()
    })

    EventBus.$on('open-info', () => {
      this.guideVisible = true
    })

    EventBus.$on('remove-examples', () => {
      this.deleteExamples()
    })
  },

  beforeDestroy () {
    EventBus.$off('add-edit')
    EventBus.$off('open-info')
    EventBus.$off('remove-examples')
  },

  methods: {
    onShowAddNewDialog () {
      this.getRevenueStreams()
      this.dialogAddNewVisible = true
    },

    onUpdateAddNewDialog () {
      this.loading = true
      Promise.all([
        this.getRevenueStreams(),
        this.getTotalCosts(),
        this.getProfitAndCashFlow()
      ])
        .then(() => {
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },

    deleteExamples () {
      this.deleteExamplesMixin('revenue-streams')
        .then(_ => { this.onUpdateAddNewDialog() })
    }
  }
}
</script>
